import { getClient } from './client'
import { getQueryParam } from 'utils/generalUtils'
import { gql } from 'graphql-request'
import { embedUrl } from 'config'

const RESEND_EMAIL = gql`
  mutation resendEmail($id: ID!) {
    resendEmail(id: $id) {
      _id
      status
    }
  }
`

const send = async (path) => {
  const response = await fetch(`${embedUrl}/embed${path}`, {
    method: 'GET'
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  const data = await response.json()

  return data
}

export const init = async (slug) => {
  const source = getQueryParam('source')
  const invite = getQueryParam('invite')
  const language = getQueryParam('language')

  const query = new URLSearchParams()

  if (source) {
    query.append('source', source)
  }

  if (invite) {
    query.append('token', invite)
  }

  if (language) {
    query.append('language', language)
  }

  const queryString = query.toString()

  const { data } = await send(
    `/u/${slug}${queryString ? `?${queryString}` : ''}`
  )

  return { data: data.initUploadPage }
}

export const uploadInstagramPost = async (
  url,
  { crop, mediaUrl, mediaType }
): Promise<Response> => {
  const res = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      crop,
      mediaUrl,
      mediaType
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(async (res) => {
    const data = await res.json()

    if (!res.ok) {
      return Promise.reject(data.error?.message || res.statusText)
    }
  })

  return res
}

export const uploadCevoidPost = async (
  url,
  { crop, file, fields, id, filename }
) => {
  const data = new FormData()

  if (crop) {
    // data.append('crop', JSON.stringify(crop))
  }

  Object.entries(fields).map(([key, value]) => {
    data.append(key, value as any)
  })

  data.append('file', file)

  const res: Response = await fetch(url, {
    method: 'POST',
    body: data
  }).then(async (res) => {
    const data = await res.text()

    if (!res.ok) {
      return Promise.reject(res.statusText)
    }
  })

  return res
}

type MediaInput = {
  location?: String
  trim?: {
    start?: Number
    end?: Number
  }
  crop?: {
    x?: Number
    y?: Number
    width?: Number
    height?: Number
  }
  caption?: String
}

type PendingUploadInput = {
  medias: MediaInput[]
  email: String
  username: String
  companySlug: String
  newMember: Boolean
}

type VerifyPendingUploadInput = {
  id?: String
  token?: String
  code?: String
}

type VerifyMemberInput = {
  email: String
  companySlug: String
  newMember: Boolean
}

const CREATE_PENDING_UPLOAD = gql`
  mutation createPendingUpload($input: PendingUploadInput!) {
    createPendingUpload(input: $input) {
      _id
      status
    }
  }
`

const VERIFY_PENDING_UPLOAD = gql`
  mutation verifyPendingUpload($input: VerifyPendingUploadInput!) {
    verifyPendingUpload(input: $input) {
      _id
      company {
        slug
      }
      medias {
        location
        type
      }
    }
  }
`

const MEMBER_VERIFICATION = gql`
  query memberVerification($input: MemberVerificationInput!) {
    memberVerification(input: $input)
  }
`

type GetGoogleStorageUploadUrlResponse = {
  assetUrl: string
  config: {
    fields: {
      key: string
      policy: string
      'x-goog-algorithm': string
      'x-goog-credential': string
      'x-goog-date': string
      'x-goog-signature': string
    }
    url: string
  }
}

async function getFileUploadURL(
  companyId: string,
  filename: string,
  contentType: string,
  size: number,
  duration?: number
): Promise<GetGoogleStorageUploadUrlResponse> {
  const data = await getClient().request(
    gql`
      query getUploadLink(
        $companyId: String!
        $filename: String!
        $contentType: String!
        $size: Int!
        $duration: Float
      ) {
        getUploadLink(
          companyId: $companyId
          filename: $filename
          contentType: $contentType
          size: $size
          duration: $duration
        )
      }
    `,
    { companyId, filename, contentType, size, duration }
  )

  return data?.getUploadLink
}

const createPendingUpload = async (
  input: PendingUploadInput
): Promise<{
  _id: String
  status: String
}> => {
  try {
    const client = getClient()
    const res = await client.request(CREATE_PENDING_UPLOAD, { input })

    return res.createPendingUpload
  } catch (error) {
    // TODO: Handle error
    throw error
  }
}

// If verify pending upload is successful, it will void if not, it will throw an error
const verifyPendingUpload = async (
  input: VerifyPendingUploadInput
): Promise<any> => {
  try {
    const client = getClient()
    const data = await client.request(VERIFY_PENDING_UPLOAD, { input })

    return data.verifyPendingUpload
  } catch (error) {
    // TODO: Handle error
    throw error
  }
}

const verifyMember = async (input: VerifyMemberInput): Promise<void> => {
  try {
    const client = getClient()
    await client.request(MEMBER_VERIFICATION, { input })
  } catch (error) {
    throw error
  }
}

const sendMemberDataRemovalEmail = async (email: String): Promise<void> => {
  const client = getClient()
  await client.request(
    gql`
      mutation sendMemberDataRemovalEmail($email: Email!) {
        sendMemberDataRemovalEmail(email: $email)
      }
    `,
    { email }
  )
}

const getMemberPostsForDataRemoval = async (input: {
  instagramAccessToken?: string
  token?: string
}): Promise<any[]> => {
  const client = getClient()
  const res = await client.request(
    gql`
      query getMemberPostsForDataRemoval(
        $input: GetMemberPostsForDataRemovalInput!
      ) {
        getMemberPostsForDataRemoval(input: $input) {
          _id
          type
          media {
            location
            thumbnail
          }
        }
      }
    `,
    { input }
  )

  return res.getMemberPostsForDataRemoval
}

const removePostAsMember = async (
  id: String,
  input: {
    instagramAccessToken?: string
    token?: string
  }
): Promise<void> => {
  const client = getClient()
  await client.request(
    gql`
      mutation removePostAsMember(
        $id: ID!
        $input: GetMemberPostsForDataRemovalInput!
      ) {
        removePostAsMember(id: $id, input: $input)
      }
    `,
    { id, input }
  )
}

const resendMail = async (id: String): Promise<void> => {
  const client = getClient()
  await client.request(RESEND_EMAIL, {
    id
  })
}

export {
  createPendingUpload,
  verifyPendingUpload,
  getFileUploadURL,
  verifyMember,
  resendMail,
  // Data removal
  sendMemberDataRemovalEmail,
  getMemberPostsForDataRemoval,
  removePostAsMember
}
